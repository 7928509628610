<template>
  <div>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin: 10px 0px"
      size="mini"
      @click="addRow"
    >添加一行
    </el-button>
    <el-button
      type="primary"
      @click="saveTable"
      style="margin: 10px 0 10px 10px"
      size="mini"
    >保存
    </el-button>
    <el-table style="width: 100%" border :data="list" :key="randomKey"
              @cell-dblclick="editData">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="left" label="利润左区间">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row[scope.column.property + 'isShow']"
            v-model="scope.row.left"
            :ref="scope.column.property"
            placeholder="利润左区间"
            @blur="alterData(scope.row,scope.column)"
            size="mini">
          </el-input>
          <span
            v-else>
            {{scope.row.left}}
            </span>
        </template>
      </el-table-column>
      <el-table-column prop="right" label="利润右区间">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row[scope.column.property + 'isShow']"
            v-model="scope.row.right"
            :ref="scope.column.property"
            placeholder="利润右区间"
            @blur="alterData(scope.row,scope.column)"
            size="mini">
          </el-input>
          <span
            v-else>
            {{scope.row.right}}
            </span>
        </template>
      </el-table-column>
      <!--<el-table-column prop="percent" label="价格下调">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row[scope.column.property + 'isShow']"
            v-model="scope.row.percent"
            :ref="scope.column.property"
            placeholder="价格调整百分比"
            @blur="alterData(scope.row,scope.column)"
            size="mini">
          </el-input>
          <span
            v-else>
            {{scope.row.percent}}
            </span>
        </template>
      </el-table-column>-->
      <el-table-column prop="one" label="一级返利">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row[scope.column.property + 'isShow']"
            v-model="scope.row.one"
            :ref="scope.column.property"
            placeholder="一级返利"
            @blur="alterData(scope.row,scope.column)"
            size="mini">
          </el-input>
          <span
            v-else>
            {{scope.row.one}}
            </span>
        </template>
      </el-table-column>
      <el-table-column prop="two" label="二级返利">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row[scope.column.property + 'isShow']"
            v-model="scope.row.two"
            :ref="scope.column.property"
            placeholder="二级返利"
            @blur="alterData(scope.row,scope.column)"
            size="mini">
          </el-input>
          <span
            v-else>
            {{scope.row.two}}
            </span>
        </template>
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="100px">
        <template slot-scope="{$index}">
          <el-row>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="delRow($index)"
            >删除</el-button
            >
          </el-row>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getConfigSetting, updateConfigSetting } from '../../../network/notify'

export default {
  name: 'RebateAdjust',
  filters: {
  },
  data () {
    return {
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      randomKey: '',
      rebateConfigType: 6
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getDetail()
  },
  methods: {
    getDetail () {
      getConfigSetting({
        type: this.rebateConfigType
      }).then(res => {
        console.log('get config setting:', res)
        const result = res.data
        if (res.status !== 200) {
          // return this.alertMessage('获取订单配置数据获取失败', 'error')
          return
        }
        this.list = result.data.content.data
        if (!this.list) {
          this.list = []
        } else {
          for (const item of this.list) {
            if (item.op === 'less' && item.left === 0) {
              item.left = ''
            }
            if (item.op === 'more' && item.right === 0) {
              item.right = ''
            }
          }
        }
        console.log('rebateConfig is:', this.list)
      }, err => {
        console.log('get data err:', err)
      })
    },
    editData (row, column) {
      row[column.property + 'isShow'] = true
      this.refreshTable()
      this.$nextTick(() => {
        this.$refs[column.property] && this.$refs[column.property].focus()
      })
    },
    alterData (row, column) {
      if (!row[column.property]) {
        return
      }
      row[column.property + 'isShow'] = false
      this.refreshTable()
    },
    refreshTable () {
      this.randomKey = Math.random()
    },
    addRow () {
      this.list.push(
        {
          leftisShow: true,
          rightisShow: true,
          oneisShow: true,
          twoisShow: true,
          left: '',
          right: '',
          one: '',
          two: '',
          op: 'equal'
        }
      )
    },
    delRow (idx) {
      this.$confirm('此操作将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
      this.list.splice(idx, 1)
    },
    saveTable () {
      console.log('table data:', this.list)
      var compare = function (prop) {
        return function (obj1, obj2) {
          var val1 = obj1[prop]
          var val2 = obj2[prop]
          if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
            val1 = Number(val1)
            val2 = Number(val2)
          }
          if (val1 < val2) {
            return -1
          } else if (val1 > val2) {
            return 1
          } else {
            return 0
          }
        }
      }
      const saveList = []
      for (const item of this.list) {
        var op = 'equal'
        if (item.left === '') {
          op = 'less'
        }
        if (item.right === '') {
          op = 'more'
        }
        saveList.push({
          left: item.left === '' ? 0 : parseInt(item.left),
          right: item.right === '' ? 0 : parseInt(item.right),
          one: item.one === '' ? 0 : parseInt(item.one),
          two: item.two === '' ? 0 : parseInt(item.two),
          op: op
        })
      }
      saveList.sort(compare('left'))
      console.log('save data:', saveList)
      const save2SrvItem = { data: saveList }
      updateConfigSetting({
        content: save2SrvItem,
        desc: '返利配置信息',
        type: this.rebateConfigType
      }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改返利信息失败', 'error')
        }
        this.alertMessage('修改返利信息成功', 'success')
        this.getDetail()
      })
    }
  }
}
</script>

<style scoped>

</style>
