<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>商品管理</template>
      <template v-slot:secondMenu>价格返利</template>
    </breadcrumb-nav>
    <el-alert style="margin-bottom: 10px;" type="warning" :title=alertTitle :closable="false" show-icon></el-alert>
    <el-card>
      <el-tabs>
        <el-tab-pane label="价格调整">
          <price-adjust></price-adjust>
        </el-tab-pane>
        <el-tab-pane label="返利调整">
          <rebate-adjust></rebate-adjust>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import PriceAdjust from './priceChildComponents/PriceAdjust'
import RebateAdjust from './priceChildComponents/RebateAdjust'

export default {
  name: 'Price',
  components: {
    PriceAdjust,
    RebateAdjust,
    BreadcrumbNav
  },
  data () {
    return {
      alertTitle: '修改后保存，价格和返利调整生效(单位：百分比)'
    }
  }
}
</script>

<style scoped>

</style>
